import React from 'react';
import { Helmet } from 'react-helmet';
import '../privacy-policy/styles.scss';

const CookieContent = () => {
    return (
        <div className='policy-wrapper'>
            <h1>Cookie Policy</h1>
            <div className='policy-header'>
                <h3>Effective Date: 27-Apr-2021</h3>
            </div>
            <div className='policy-section'>
                <p>
                    At SpringRole Inc. (referred as "Springworks"), we believe in bring transparent about how we collect
                    and use data. This policy provides the information about how and when we use cookies for these
                    purposes. Capitalized terms used in this policy but not defined have the meaning set forth in our
                    Privacy Policy, which also includes additional details about the collection and use of information
                    at Springworks.
                </p>
            </div>
            <div className='policy-section'>
                <h2 className='no-padding'>What is a cookie?</h2>
                <p>
                    Cookies are small data files that are placed on your computer or mobile device when you visit a
                    website, mobile app or use an online platform. Cookies are widely used by online service providers
                    to facilitate and help to make the interaction between users and websites, mobile apps and online
                    platforms faster and easier, as well as to provide reporting information.
                </p>
                <p>
                    Cookies set by the website and/or mobile app and/or platform owner (in this case, Springworks) are
                    called "first party cookies". Cookies set by parties other than the website and/or mobile app and/or
                    platform owner are called "third party cookies". Third party cookies enable third party features or
                    functionality to be provided on or through the website and/or mobile app and/or platform (e.g. like
                    advertising, interactive content and analytics). The parties that set these third party cookies can
                    recognize your computer or device both when it visits the website and/or mobile app and/or platform
                    in question and also when it visits certain other websites and/or mobile apps and/or platforms.
                </p>
            </div>

            <div className='policy-section'>
                <h2 className='no-padding'>Does Springworks use cookies?</h2>
                <p>
                    Yes. Springworks uses cookies and similar technologies like single-pixel gifs and web beacons. We
                    use both session-based and persistent cookies. Springworks sets and accesses our own cookies on the
                    domains operated by Springworks and its corporate affiliates (collectively, the “Sites”). In
                    addition, we use third party cookies, like Google Analytics.
                </p>
            </div>

            <div className='policy-section'>
                <h2 className='no-padding'>How is Springworks using cookies?</h2>
                <p>
                    Some cookies are associated with your account and personal information in order to remember that you
                    are logged in and which workspaces you are logged into. Other cookies are not tied to your account
                    but are unique and allow us to carry out analytics and customization, among other similar things.
                </p>
                <p>
                    Cookies can be used to recognize you when you visit a Site or use our Services, remember your
                    preferences, and give you a personalized experience that’s consistent with your settings. Cookies
                    also make your interactions faster and more secure.
                </p>
            </div>

            <div className='policy-section'>
                <h2 className='no-padding'>Categories of Use</h2>
                <p>
                    <div>Authentication</div>
                    If you're signed in to our Services, cookies help us show you the right information and personalize
                    your experience.
                </p>
                <p>
                    <div>Security</div>
                    We use cookies to enable and support our security features, and to help us detect malicious
                    activity.
                </p>
                <p>
                    <div>Preferences, features and services</div>
                    Cookies can tell us which language you prefer and what your communications preferences are. They can
                    help you fill out forms on our Sites more easily. They also provide you with features, insights, and
                    customized content.
                </p>
                <p>
                    <div>Marketing</div>
                    We may use cookies to help us deliver marketing campaigns and track their performance (e.g., a user
                    visited our Help Center and then made a purchase). Similarly, our partners may use cookies to
                    provide us with information about your interactions with their services, but use of those
                    third-party cookies would be subject to the service provider’s policies.
                </p>
                <p>
                    <div>Performance, Analytics and Research</div>
                    Cookies help us learn how well our Sites and Services perform. We also use cookies to understand,
                    improve, and research products, features, and services, including to create logs and record when you
                    access our Sites and Services from different devices, such as your work computer or your mobile
                    device.
                </p>
            </div>

            <div className='policy-section'>
                <h2 className='no-padding'>How are cookies used for advertising purposes?</h2>
                <p>
                    Cookies and other ad technology such as beacons, pixels, and tags help us market more effectively to
                    users that we and our partners believe may be interested in Springworks. They also help provide us
                    with aggregated auditing, research, and reporting, and know when content has been shown to you.
                </p>
            </div>

            <div className='policy-section'>
                <h2 className='no-padding'>
                    What can you do if you don't want cookies to be set or want them to be removed, or if you want to
                    opt out of interest-based targeting?
                </h2>
                <p>
                    Some people prefer not to allow cookies, which is why most browsers give you the ability to manage
                    cookies to suit you. In some browsers you can set up rules to manage cookies on a site-by-site
                    basis, giving you more fine-grained control over your privacy. What this means is that you can
                    disallow cookies from all sites except those that you trust.
                </p>
                <p>
                    Browser manufacturers provide help pages relating to cookie management in their products. Please see
                    below for more information.
                    <ul>
                        <li>
                            <a
                                href={
                                    'https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en'
                                }
                                target={'_blank'}
                                rel='noreferrer noopener'
                            >
                                Google Chrome
                            </a>
                        </li>
                        <li>
                            <a
                                href={
                                    'https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d'
                                }
                                target={'_blank'}
                                rel='noreferrer noopener'
                            >
                                Internet Explorer
                            </a>
                        </li>
                        <li>
                            <a
                                href={'https://support.mozilla.org/en-US/products/firefox/protect-your-privacy/cookies'}
                                target={'_blank'}
                                rel='noreferrer noopener'
                            >
                                Mozilla Firefox
                            </a>
                        </li>
                        <li>
                            <a
                                href={'https://support.apple.com/en-in/guide/safari/sfri11471/mac'}
                                target={'_blank'}
                                rel='noreferrer noopener'
                            >
                                Safari (Desktop)
                            </a>
                        </li>
                        <li>
                            <a
                                href={'https://support.apple.com/en-in/guide/mdm/mdmf7d5714d4/web'}
                                target={'_blank'}
                                rel='noreferrer noopener'
                            >
                                Safari (Mobile)
                            </a>
                        </li>
                        <li>
                            <a
                                href={
                                    'https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DAndroid&hl=en'
                                }
                                target={'_blank'}
                                rel='noreferrer noopener'
                            >
                                Android Browser
                            </a>{' '}
                        </li>
                        <li>
                            <a
                                href={'https://help.opera.com/en/latest/web-preferences/#cookies'}
                                target={'_blank'}
                                rel='noreferrer noopener'
                            >
                                Opera
                            </a>
                        </li>
                        <li>
                            <a
                                href={'https://help.opera.com/en/latest/web-preferences/#cookies'}
                                target={'_blank'}
                                rel='noreferrer noopener'
                            >
                                Opera Mobile
                            </a>
                        </li>
                    </ul>
                    For other browser, please consult the documentation that your browser manufacturer provides.
                </p>
                <p>
                    You can opt out of interest-based targeting provided by participating ad servers through the Digital
                    Advertising Alliance (https://youradchoices.com). In addition, on your iPhone, iPad or Android, you
                    can change your device settings to control whether you see online interest-based ads.
                </p>
                <p>
                    If you limit the ability of websites and applications to set cookies, you may worsen your overall
                    user experience and/or lose the ability to access the services, since it will no longer be
                    personalized to you. It may also stop you from saving customized settings, like login information.
                </p>
            </div>

            <div className='policy-section'>
                <h2 className='no-padding'>Does Springworks respond to Do Not Track Signals?</h2>
                <p>
                    Our Sites and Services do not collect personal information about your online activities over time
                    and across third-party websites or online services. Therefore, “do not track” signals transmitted
                    from web browsers do not apply to our Sites or Services, and we do not alter any of our data
                    collection and use practices upon receipt of such a signal.
                </p>
            </div>
        </div>
    );
};

const CookieHelmet = (
    <Helmet>
        <title>Cookie Policy | Springworks</title>
        <meta
            data-react-helmet='true'
            name='description'
            content='Springworks is the embodiment of our vision and ambition for building the tools and products to simplify hiring and recruiting for the recruiting future.'
        />
        <meta property='og:locale' content='en_US' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content='Cookie Policy | Springworks' />
        <meta
            property='og:description'
            content='Springworks is the embodiment of our vision and ambition for building the tools and products to simplify hiring and recruiting for the recruiting future.'
        />
        <meta property='og:url' content='https://www.springworks.in/about-us/' />
        <meta property='og:site_name' content='Springworks' />
        <meta
            property='og:image'
            content='https://assets-springengage.s3.amazonaws.com/web/springworks-solving-hr-challenges.png'
        />
        <meta
            property='og:image:secure_url'
            content='https://assets-springengage.s3.amazonaws.com/web/springworks-solving-hr-challenges.png'
        />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='600' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta
            name='twitter:description'
            content='Springworks is the embodiment of our vision and ambition for building the tools and products to simplify hiring and recruiting for the recruiting future.'
        />
        <meta name='twitter:title' content='Cookie Policy | Springworks' />
        <meta name='twitter:creator' content='@springroleinc' />
        <meta
            name='twitter:image'
            content='https://assets-springengage.s3.amazonaws.com/web/springworks-solving-hr-challenges.png'
        />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='600' />
        <link rel='canonical' href='https://www.springworks.in/cookie-policy/' />
    </Helmet>
);

const CookiePolicy = () => {
    return (
        <>
            {CookieHelmet}
            <CookieContent />
        </>
    );
};

export default CookiePolicy;
